@import '../../styles/breakpoints';

.background {
  background-color: var(--color-white);
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container-width);
  padding-top: var(--size-spacing-05);
  padding-bottom: var(--size-spacing-05);

  @include device-small {
    display: flex;
    padding-left: var(--size-spacing-05);
    padding-right: var(--size-spacing-05);
  }
}

.image {
  max-width: 100%;
  padding: 0 var(--size-spacing-05);

  @include device-small {
    max-width: 40%;
  }
}

.content {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 var(--size-spacing-05);
}
